.news-story{
    border: solid 2px black;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: grey;
    border-radius: 15px;
    margin: 5px;
}
.news-image{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.story-published{
    color: rgba(192, 192, 192, 0.438);
    font-size: 10px;
}
.news-card {
    font-size: small;
    max-width: 99%;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px;
  }
  

.news-card:hover {
    background-color: #1e2124;
  }
  
  .news-card h4 {
    margin-top: 1px;
  }
  
  .news-card h2 {
    margin-top: -10px;
    font-weight: bolder;
  }
  
  .article-img {
    display: flex;
    justify-content: center;
  }
  .article-img-thumbnail {
    width: 250px;
  }
  .article-text{
    word-wrap: normal;
    margin: 15px;
  }
  @media screen and (max-width: 650px) {
    .news-card{
      flex-direction: column;
    }
    .article-img-thumbnail {
      width: 100%;
    }
  }
