.dash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: gray;
  color: white;
  background-color: black;
  max-width: 95vw;
  margin: 1vw;
}
.dashboard {
  height: calc(100% - 100px);
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  max-width: 95vw;
}

.dash-portfolio {
  padding-bottom: 20px;
  border-bottom: 2px solid #1e2124;
}
.dash-movers {
  margin-top: 10px;
  border-bottom: 2px solid #1e2124;
  
}


.dash-news {
  border-top: 2px solid #1e2124;
  margin-top: 10px;
  padding-top: 10px;
}
