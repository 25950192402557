/* TODO Add site wide styles */
@import url('./fonts.css');

body {
  font-family: Monsterrat-Regular;
  background-color: black;
}
#full-page{
  margin-top: 40px;
  color: white;
  display: grid;
  grid-template-rows: 10% 80% 10%;
}
.footer{
  display: block;
  background-color: aliceblue;
  height: 100px;
  position: relative;
  grid-row: 3;
}
