.search-bar {
  position: relative;
  background-color: none;
  border: 1px solid #444444;
  border-radius: 4px;
  padding: 5px;
  
  width: 30vw;
}

.input-wrapper{
  display: flex;
}

#search-icon {
  color: green;
}

.searchbar-input {
  background-color: black;
  border: none;
  color: white;
  margin-left: 10px;
  width: 100%;
}

.searchbar-input:focus {
  outline: none;
}

.results-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  background-color: #444444;
  width: 500px;
  overflow-y: scroll;
}

.results-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.results-list li {
  padding: 8px 16px;
}

.results-list li:hover {
  background-color: black;
}

.results-list li a {
  color: white;
  text-decoration: none;
}

.results-list li a:hover {
  font-weight: bolder;
}
