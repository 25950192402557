.sd-container {
  width:auto;
  padding: 10px 5%;
  height:max-content;
  /* margin: 0px 20px; */
  /* overflow-y: scroll;  */

  flex-direction: column-reverse;
  display: flex;
  justify-content: center;
  align-items: center;

  /* grid-template-rows: 10vh auto;
  grid-template-columns: 0.5fr 2.3fr 0.5fr;
  gap: 0px 0px; */

  overflow: hidden;
  background-color: gray;
  margin-top: 70px;
  color: white;
  background-color: black;
  position: relative;
  top: 10px;

}
.sd-container > div{
  max-width: 100%;
}
.details-about{
  display: flex;
  flex-direction: column;
}
.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  top:0px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  width: 100vw;
  max-width: 100%;
  /* position: relative; */
}

.details-name-price{
  width: 98%;
  margin: 30px;
}
.details-graph {
  z-index: 0;
  width: 99vw;
  max-width: 100%;
  overflow: hidden;
  justify-content: end;
}

.details::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.details::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.sd-graph-buttons-div {
  display: flex;
  gap: 5px;
}

.sd-graph-button {
  border: none;
  background-color: black;
  color: white;
  font-weight: bolder;
  margin-top: 5px;
  margin-bottom: 10px;
}

.sd-graph-button:hover {
  color: green;
}

.about-title {
  border-bottom: 2px solid #1e2124;
  border-top: 2px solid #1e2124;
}

.details-info {
  display: flex;
  width: 100%;
  /* gap: 100px; */
}
@media screen and (max-width: 700px) {
  .details-info{
    flex-direction: column;
  }
  .details-keystats{

    align-items: center;
    justify-content: center;
  }
    .info-card{
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

.info-item {
  margin-top: 5px;
  font-weight: bold;
  width: 180px;
}
.info-item-bottom {
  margin-top: 5px;
  width: 180px;
}

.details-keystats-name {
  border-bottom: 2px solid #1e2124;
}

.details-keystats {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 100px;
  row-gap: 10px;
  margin-top: 10px;
}

.details-news {
  border-bottom: 2px solid #1e2124;
}

.sd-link {
  text-decoration: none;
  color: inherit;
}
.stock_news{
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  justify-content: start;
  align-content: start;
}



.actions{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
}


.action-buttons {
  display: flex;
  height: 100px;
  background-color: #1e2124;
  border: 2px solid #282d30;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 95%;
  padding: 5px;
  margin: 10px;
}

.sd-action-buttons-container {
  display: flex;
}
.sd-action-buttons-container > div{
  margin: 20px;
}
.sd-button {
  height: 50px;
  width: 150px;
  margin: 5px;
  border: 1px solid #282d30;
  background-color: #1e2124;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: 200;
}

.sd-button:hover {
  color: green;
  font-weight: 200;
  border: 1px solid darkgreen;
  cursor: pointer;
}

.sd-buttons-words-below {
  margin-top: 15px;
  padding: 5px;
}

.sd-stock-price {
  margin-top: -10px;
}

.stockowned-amount {
  margin-top: -1px;
}
.stc-det-add-to-list-cont {
  /* background-color: red; */
  display: flex;
  margin-top: 10px;
}
.stc-det-add-to-list {
  margin: auto;
  height: 30px;
  width: 100px;
  background-color: rgb(25, 163, 29);
  border-radius: 18px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 10px;
}
.stc-det-add-to-list-cont button {
  font-size: 10px;
  font-weight: bold;
}
