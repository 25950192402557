.profile-dropdown {
  right: 22px;
  position: absolute;
  
  z-index: 1;

  display: flex;  
  flex-direction: column;
  /* justify-content: flex-start; */
  /* align-items: center; */

  /* padding: 5px; */

  background-color: black;
  border: 2px solid grey;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
 

  scrollbar-width: none;
  top:7vh;
  
  max-height: 60vh;
  overflow: scroll;
  transition: 1s;
}
.dropdown-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}
.search-div{
  max-width: 40%;
}
.user-info{
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.searchbar-dropdown {
  position: absolute;
}
.use-div{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width:40vw;
}
.icon-div{
  
  padding-left: 3vw;
}
.hidden {
  /* display: none; */
  right:-400px
}
.right-bar {
  display: flex;
  flex-direction: row;
  background-color: black;

  align-items: center;
  justify-content: end;
}
.bar {
  display: flex;
  flex-direction: row;
  background-color: black;
  color: grey;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  z-index: 1;
}
.bar > div {
  margin: 5px;
  max-width: 99vw;
}
.bar{
  padding: 10px;
  border-bottom: solid 1px grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-link {
  text-decoration: none;
  color: white;
}

.navbar-link:hover {
  color: green;
}

#feather-icon {
  color: green;
  height: fit-content;
  width: 30px;
}

.account-button {
  border: none;
  background-color: black;
  color: white;
  height: fit-content;
}

.account-button:hover {
  color: green;
}

#account-icon {
  width: 30px;
  height: 30px;
}
.bar-home-about {
  display: flex;
  padding-right: 5vw;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
