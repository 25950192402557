.rh-img {
  height: 500px;
  margin: 20px;
}

.hp-img-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* gap: 10px; */
}

.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* gap: 10px; */
}

.header-hp {
  color: green;
  font-size: 30px;
}

.hp-buttons {
  display: flex;
  gap: 10px;
}

.about-me-bottom {
  /* background-color: red; */
  height: 100px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.about-me-links {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* background-color: #dda15e; */
  border: 1px solid #4362ee1c;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 15px;
  color: white;
  transition: 0.3s;
}
.about-me-links:hover {
  /* background-color: #4362ee1c; */
  background-color: transparent;
  /* border-color: #dda15e; */
  /* color: #dda15e; */
  transform: scale(1.2);
}
.about-me-portfolio {
  background-color: #dda15e;
}
.about-me-portfolio:hover {
  color: #dda15e;
  border-color: #dda15e;
}
.about-me-github {
  background-color: #a8dadc;
}
.about-me-github:hover {
  /* background-color: ; */
  color: #a8dadc;
  border-color: #a8dadc;
}
.about-me-resume {
  background-color: #9b5de5;
}
.about-me-resume:hover {
  color: #9b5de5;
  border-color: #9b5de5;
}

.about-text {
  font-size: 12pt;
  white-space: initial;
}

.about-header {
  display: flex;
  flex-direction: column;
  max-width: 800px;
}

h6 {
  margin: 0;
}
