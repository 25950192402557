.movers-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #1e2124;
  border-radius: 8px;
  gap: 15px;
  padding: 1vw;
  width: 100%;
  height: 100%;
}
.movers-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.movers-div-for-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3vw;
  margin-bottom: 10px;
}
@media (max-width:705px){
  .movers-div-for-cards{
    flex-direction: column;
  }
  .movers-card{
    width:100%;
    flex-direction: row;
    justify-content: center;
  }
}
/* 
 */

.movers-info {
  font-size: 30px;
  font-weight: bolder;
}

.movers-info-change {
  font-weight: bolder;
}

