.wat-lst-pri-cont {
  /* background-color: green; */
  /* width: 220px; */
  /* z-index: 1; */
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 25px;
}
.wat-lst-header {
  /* background-color: teal; */
  /* margin-top: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(200, 196, 196);
  /* padding: 5px; */
}
.wat-lst-header i {
  margin-right: 10px;
  /* background-color: red; */
}
.wat-lst-header p {
  font-size: 12px;
  font-weight: bold;
  margin-left: 12px;

  /* border-bottom: 1px solid green; */
}
/* .wat-lst-header button {
    background: none;
    border: none;
    color: rgb(159, 157, 157);
} */
.cre-lst-openModalBtn {
  background: none;
  border: none;
  color: rgb(159, 157, 157);
}
.cre-lst-openModalBtn:hover {
  cursor: pointer;
}

.wat-lst-mn-ctn {
  width: 80%;
  border: 1px solid rgb(200, 196, 196);
  margin-top: 50px;
  border-radius: 4px;
}
.fa-ellipsis {
  color: white;
  opacity: 0;
  cursor: pointer;
}
.wat-lst-bod-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: teal; */
}

.wat-lst-bod-head:hover .fa-ellipsis {
  /* background-color: green; */
  opacity: 1;
}

.wat-lst-bod-head p {
  font-size: 10px;
  margin-left: 12px;
}
.wat-lst-bod-head i {
  margin-right: 20px;
  /* transform: rotate(180deg); */
}
.wat-lst-bod-bod {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  height: 40px;
  z-index: 1;
}
.wat-lst-bod-bod p {
  font-size: 11px;
  font-weight: bold;
  margin-left: 11px;
}
.wat-lst-bod-head-menu {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: row;
  align-items: center;
  /* background-color: red; */
}
.wat-lst-bod-head-menu button {
  background: none;
  border: none;
}
/* .wat-lst-opt-popup {
    margin-bottom: 8px;
} */
.fa-sort-down {
  margin-bottom: 7px;
  /* padding-bottom: 4px; */
  /* background-color: green; */
}
.wat-lst-mod-btn-cont {
  position: absolute;
  top: 26px;
  /* left: 5px; */
  right: -10px;
  background-color: white;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  border-radius: 4px;
  /* border: 1px solid grey; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: black;
  z-index: 10;
  border: 1px solid #1e2124;
  padding: 8px;
}

.wat-lst-mod-btn-cont:hover {
  cursor: pointer;
}
.wat-lst-delete-mod-btn {
  padding: auto;
  text-decoration: underline;
  font-size: 10px;
  font-weight: bold;
  background: none;
  border: none;
  text-underline-offset: 2px;
  background-color: black;
  color: white;

  /* word-spacing: 6px; */
}

.wat-lst-delete-mod-btn:hover {
  cursor: pointer;
}
.fa-circle-xmark {
  margin-right: 8px;
}
.wat-edit-modal-btn {
  color: white;
  background-color: black;
  font-size: 10px;
  word-spacing: 4px;
  border: none;
  /* border-bottom: 1px solid white; */
  padding: 0;
}
.fa-gear {
  font-size: 10px;
  margin-left: 4px;
}
.wat-edit-modal-btn-txt {
  font-size: 10px;
  margin-left: 10px;
  border-bottom: 1px solid white;
  transition: 0.3s;
}
.wat-del-mod-btn {
  color: white;
  background-color: black;
  font-size: 10px;
  word-spacing: 4px;
  border: none;
  /* border-bottom: 1px solid white; */
  padding: 0;
}
.fa-xmark {
  font-size: 12px;
  margin-left: 4px;
}
.wat-edit-modal-btn-txt:hover {
  color: rgb(93, 209, 93);
}
.cre-wat-lst-plus-btn {
  background-color: black;
  border: none;
}
.fa-plus {
  color: white;
}
.fa-bolt {
  color: gold;
}
