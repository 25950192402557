

.menu-bar{
    margin:1px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    top: 1px;
  }
  .fixed-bar{
    position: relative;    
    display: flex;
    flex-direction: column;
    background-color: black;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 5px 10px;
  }
  .bar-container{
    position:absolute;
    scrollbar-width: none;
    top:3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: black;
    border: 2px solid grey;
    border-radius: 13px;
    padding: 20px 0px;
    max-height: 60vh;
    overflow: scroll;
  }
  
  .hide{  
    max-height: 0px;
    max-width: 20px;
    overflow: hidden;
  }
  .show{
    margin-right: 0px;
  }
  .bar-container > div{
    display: flex;
    align-items: center;
    justify-content: center;
  }