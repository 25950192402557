.rh-img {
  height: 500px;
  margin: 20px;
}

.hp-img-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
  /* gap: 10px; */
}

.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* gap: 10px; */
}

.header-hp {
  color: green;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hp-buttons {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 1500px) and (min-width:801px) {
  .hp-img-container{
    max-width: 900px;
  }
}
@media screen and (max-width: 800px) {

}